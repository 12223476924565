import { Button, ButtonProps } from "@chakra-ui/react";

interface IActionButton extends ButtonProps {
  label: string;
  onClick: () => void;
}

const ActionButton = ({ label, onClick, ...rest }: IActionButton) => {
  return (
    <Button
      borderRadius={"full"}
      onClick={onClick}
      _loading={{ bg: "blackAlpha.300" }}
      bg={"blackAlpha.800"}
      {...rest}
      colorScheme="blackAlpha"
      fontSize={"sm"}
      h={10}
      px={5}
      my={10}
      fontWeight={"semibold"}
      letterSpacing={"wide"}
    >
      {label}
    </Button>
  );
};

export default ActionButton;
