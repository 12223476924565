import { Box, Button, Collapse, useColorModeValue } from "@chakra-ui/react";
import React, { ReactElement } from "react";

type Props = {
  isOpen?: boolean;
  isOneTimeCTA?: boolean;
  children: ReactElement;
  startingHeight: string;
};

export default function CustomCollapse({ isOpen, children, isOneTimeCTA, startingHeight }: Props) {
  const [show, setShow] = React.useState(isOpen);

  const handleToggle = () => setShow(!show);
  const darkSlate = useColorModeValue("linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) )", "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2) )");

  const darkSlatee = useColorModeValue("white", "slate.800");

  return (
    <Box>
      <Collapse startingHeight={show ? "100%" : startingHeight} animateOpacity={true}>
        {children}
      </Collapse>
      {((isOneTimeCTA && !show) || !isOneTimeCTA) && (
        <Box w="92vw" h="100px" background={show ? "transparent" : darkSlate} pos={"absolute"} bottom={"125px"} as="center">
          <Button
            pos={"absolute"}
            onClick={handleToggle}
            left={"50%"}
            transform={"translate(-50%,-0%)"}
            bottom={-5}
            bg={darkSlatee}
            variant={"outline"}
            px={4}
            py={1}
            colorScheme="blackAlpha"
            fontWeight="normal"
            fontSize={"sm"}
            borderRadius="full"
            color="linear(to-r, teal.500, green.500)"
            _hover={{
              color: "green",
            }}
          >
            Show {show ? "Less" : "More"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
